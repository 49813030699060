// ReExamCard.js
import React from "react";
import { Button } from "@mantine/core";
import "./ReExamCard.css";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import DecorativeEmptyCircle from "../DecorativeEmptyCircle/DecorativeEmptyCircle";
import showNotification from "../../utils/showNotification";

const ReExamCard = ({ lesson, title }) => {
  const navigate = useNavigate();
  const currentDate = moment();
  const examInitDate = moment(lesson.data.initDate);
  const examEndDate = moment(lesson.data.endDate);

  const isExamAvailable = currentDate >= examInitDate && currentDate <= examEndDate;


  const handleOnClick = () => {
    if (isExamAvailable) {
      navigate(
        `/campus/antes-de-evaluar/${encodeURIComponent(title)}/${
          lesson.data._id
        }`
      );
    } else {
      showNotification({
        color: "yellow",
        status: "warning",
        title:
          "Parece que aún no es la fecha del examen. Podrás ingresar ese mismo día.",
      });
    }
  };

  return (
    <div
      className="coursesCards-reExam-father-div"
      key={lesson.data._id}
      onClick={() => handleOnClick()}
    >
      <DecorativeEmptyCircle
        size={200}
        opacity={0.5}
        top={-100}
        left={780}
        color="var(--color-tertiary-dark)"
        borderWidth={32}
        zIndex={-1}
      />
      <DecorativeEmptyCircle
        size={130}
        opacity={0.5}
        top={-60}
        left={300}
        color="var(--color-tertiary-dark)"
        borderWidth={32}
        zIndex={-1}
      />
      <div className="coursesCards-reExam-div-date">
        <span className="coursesCards-reExam-text-span-date">Examen</span>
        <span className="coursesCards-reExam-span-date">
          {moment(lesson.data.initDate).format("DD/MM")}
        </span>
      </div>
      <div className="coursesCards-reExam-description-color">
        <p className="coursesCards-reExam-description">{lesson.data.name}</p>
        <div className="coursesCards-reExam-buttons-div">
          <Button
            fw="normal"
            color="var(--color-tertiary-dark)"
            disabled={!isExamAvailable}
            onClick={() =>
              navigate(
                `/campus/antes-de-evaluar/${encodeURIComponent(title)}/${
                  lesson.data._id
                }`
              )
            }
          >
            Ir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReExamCard;
