import React, {useEffect, useState} from 'react';
import './Questionnaire.css';
import QuestionIndicator from "../QuestionIndicator/QuestionIndicator";
import Timer from "../Timer/Timer";
import ExamQuestion from "../../../components/ExamQuestion/ExamQuestion";
import {Button} from "@mantine/core";
import QuestionNavigator from "../QuestionNavigator/QuestionNavigator";

const validateNotEmpty = (value, setError) => {
    if (value !== null && value.trim() !== '') {
        setError(null)
        return true;
    } else {
        setError('Por favor, elija una respuesta')
        return false;
    }
};


function Questionnaire(props) {
    const {title, questions, timeLimit, handleSubmit, reviewMode, setReviewMode} = props;

    const [error, setError] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState(reviewMode ? questions[0].userAnswer : null);

    useEffect(() => {
        if (reviewMode) {
            setCurrentQuestion(0);
            setCurrentAnswer(questions[0].userAnswer);
        }
    }, [reviewMode]);


    const handleNextQuestion = (targetIndex = null) => {
        const nextQuestionIndex = targetIndex !== null ? targetIndex : currentQuestion + 1;
        const isLastQuestion = nextQuestionIndex === questions.length;
    
        // If we are in review mode, we just need to change the current question and answer
        if (reviewMode) {
            if (isLastQuestion) {
                setReviewMode(false);
                return;
            }
            setCurrentQuestion(nextQuestionIndex);
            setCurrentAnswer(questions[nextQuestionIndex].userAnswer);
            return;
        }
    
        // If we are not in review mode, we need to validate the current answer
        if (!isLastQuestion) {
            if (validateNotEmpty(currentAnswer, setError)) {
                // If the answer is valid, we set the value in the form and move to the next question
                questions[currentQuestion].userAnswer = currentAnswer;
                setCurrentAnswer(null);
                setCurrentQuestion(nextQuestionIndex);
            }
        } else {
            // If we are in the last question, we need to validate the current answer and submit the form
            if (validateNotEmpty(currentAnswer, setError)) {
                questions[currentQuestion].userAnswer = currentAnswer;
                // Check if there are any unanswered questions
                const unansweredQuestions = questions.filter(question => !question.userAnswer);
                if (unansweredQuestions.length === 0) {
                    // If there are no unanswered questions, submit the form
                    handleSubmit(questions);
                } else {
                    // If there are unanswered questions, show an error
                    setError('Por favor, responda todas las preguntas antes de finalizar.');
                }
            }
        }
    };
    

    return (
        <div className="questionnaire__wrapper">
            <h1 className="questionnaire__title">{title}</h1>
            <div className="questionnaire__indicatorsContainer">
                <QuestionIndicator
                    currentQuestion={currentQuestion + 1}
                    amountOfQuestions={questions.length}
                />
                {!reviewMode &&
                <Timer
                    timeLimit={timeLimit}
                    questions={questions}
                    submitExam={handleSubmit}/>
                }
            </div>
            <div className="questionnaire__container">
                <div className="questionnaire__questionContainer">
                    <ExamQuestion
                        title={questions[currentQuestion].title}
                        questionText={questions[currentQuestion].questionText}
                        answers={questions[currentQuestion].answers}
                        trueAnswer={questions[currentQuestion].trueAnswer || null}
                        image={questions[currentQuestion].image}
                        currentAnswer={currentAnswer}
                        setCurrentAnswer={setCurrentAnswer}
                        error={error}
                    />
                </div>
                <div className="questionnaire__questionSelector__wrapper">
                    <h2 className="questionnaire__questionSelector__title">Navegación de preguntas</h2>
                    <QuestionNavigator
                        questions={questions}
                        currentQuestion={currentQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        setCurrentAnswer={setCurrentAnswer}
                        handleNextQuestion={handleNextQuestion}
                    />
                </div>
            </div>
            <Button className="questionnaire__nextButton" type="button" bg={"var(--color-primary)"}
                    onClick={() => handleNextQuestion()}>
                {currentQuestion < questions.length - 1 ? 'Siguiente' : 'Finalizar'}
            </Button>

        </div>
    );
}

export default Questionnaire;