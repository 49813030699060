import { Text, Title, Button } from "@mantine/core";
import "./DiscountBanner.css";
import { useEffect } from "react";

export function DiscountBanner({ setShowBanner, discountInfo, setModalOpen, handleDismiss }) {
  const handleClick = () => {
    setShowBanner(false);
  };

  const handleSnooze = () => {
    setModalOpen(false);
    sessionStorage.setItem("modalSnoozed", "true");
};

  const handleDismissButton = () => {
    setModalOpen(false)
    handleDismiss()
  };

  useEffect(() => {
    const isSnoozed = sessionStorage.getItem("modalSnoozed") === "true";
    if (isSnoozed) {
        setModalOpen(false);
    }
}, []);


  return (
    <div className="discountBanner-wrapper">
      <div className="discountBanner-body">
        <Title className="discountBanner-title"> {discountInfo.title} </Title>
        <Text fz="sm" c="dimmed">
          {discountInfo.description}
        </Text>
        <div className="discountBanner-controls">
          <div><Button fw="normal" color="#e1568b" onClick={handleClick} className="discountBanner-control">
            Completar encuesta
          </Button>
          </div>
          <div className="discountBanner-secondButtons">
          <Button fw="normal" color="#e1568b" variant="white"  onClick={handleSnooze} className="discountBanner-control">
            Recordarme más tarde
          </Button>
          <Button fw="normal" color="#FFFFFF" variant="outline" onClick={handleDismissButton} className="discountBanner-control">
            No quiero el descuento
          </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
