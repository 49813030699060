import "survey-core/defaultV2.min.css";
import "./SurveyFormat.css";
import "survey-core/survey.i18n.js";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Badge, Button, Modal, ScrollArea } from "@mantine/core";
import { DiscountBanner } from "./DiscountBanner/DiscountBanner";
import DecorativeEmptyCircle from "../DecorativeEmptyCircle/DecorativeEmptyCircle";
import themeConfig from "../../utils/surveyThemes"; //util to customize surveyTheme as you want
import axios from "axios";
import { IconCopy } from "@tabler/icons-react";
import showNotification from "../../utils/showNotification";

const SurveysFormat = ({ surveyJson, handleDismiss, surveyId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [benefitModal, setBenefitModal] = useState(false);
  const [benefitContent, setBenefitContent] = useState(null);
  const [showBanner, setShowBanner] = useState(surveyJson?.benefit?.thereIsBenefit);
  const { authToken } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);

  const generateBenefitContent = (response) => {
    const handleCopy = () => {
      navigator.clipboard
        .writeText(response.value)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
          showNotification({
            color: "green",
            status: "success",
            title: "Código copiado al portapapeles",
            message:
              "Guarda tu codigo en un lugar seguro y comunícate con ventas para canjearlo.",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    };
    setBenefitModal(true);

    // Definimos los textos que cambian según el tipo de beneficio
    const isDiscount = response.type === "discount";
    const headerText = isDiscount ? "¡Felicitaciones!" : "¡Genial!";
    const mainText = isDiscount
      ? "¡Obtuviste un código de descuento para nuestra carta de cursos."
      : "¡Obtuviste una nueva unidad académica de regalo!";
    const extraText = isDiscount
      ? "Consulta con el equipo de ventas como canjearlo!"
      : "O comunicate con ventas para más información.";
    const benefitLabel = isDiscount
      ? "Tu código de descuento:"
      : "Tu unidad académica:";
    const benefitValue = response.value;
    const buttonText = isDiscount ? "Canjear descuento" : "Ir a mis cursos";

    // Renderizamos el contenido con los textos variables
    setBenefitContent(
      <div className="surveyFormat-benefitModal-wrapper">
        <h4 className="surveyFormat-benefitModal-header">{headerText}</h4>
        <p className="surveyFormat-benefitModal--mainText">{mainText}</p>
        {isDiscount && (
          <span className="surveyFormat-benefitModal--spanText">
            {extraText}
          </span>
        )}
        {!isDiscount && (
          <p className="surveyFormat-benefitModal--mainText">
            Revisa la sección "Mis Cursos" en donde ya podrás encontrarla
            disponible.
          </p>
        )}
        <div className="surveyFormat-benefitModal-benefitWrapper">
          <p className="surveyFormat-benefitModal-benefitText">
            {benefitLabel}
          </p>
          <div className="surveyFormat-benefitModal-benefitBadge-wrapper">
            <Badge
              classNames={{
                root: "surveyFormat-benefitModal-badgePositioning",
              }}
              fw="normal"
              color="#e1568b"
            >
              {benefitValue}
            </Badge>
            {isDiscount && (
              <IconCopy
                onClick={handleCopy}
                className={`surveyFormat-benefitModal-copyIcon ${
                  copied ? "surveyFormat-benefitModal-copyIcon-copied" : ""
                }`}
              />
            )}
          </div>
        </div>
        <div className="surveyFormat-benefitModal-buttonsDiv">
          <Button
            color="#43A4D4"
            variant="filled"
            fw="normal"
            onClick={() => setBenefitModal(false)}
          >
            Cerrar
          </Button>
          <Button
            color="#e1568b"
            fw="normal"
            component="a"
            href={
                isDiscount
                ? "https://api.whatsapp.com/send/?phone=%2B5493512086545&text=%C2%A1Hola%21+Complet%C3%A9+la+encuesta+y+quiero+averiguar+c%C3%B3mo+usar+mi+descuento.+%C2%A1Gracias%21"
                : "/campus/mis-cursos"
            }
            target={isDiscount ? "_blank" : "_self"}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  };

  const surveyModel = new Model(surveyJson);

  surveyModel.locale = "es";
  surveyModel.applyTheme(themeConfig); //apply custom theme to Survey

  surveyModel.onComplete.add(async function (sender) {
    const formattedAnswers = Object.entries(sender.data).map(
      ([key, value]) => ({
        questionName: key,
        response: value,
      })
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}campus/users/uploadSurvey`,
        { surveyAnswers: formattedAnswers, surveyId: surveyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      setModalOpen(false);
      if (surveyJson?.benefit?.thereIsBenefit) {
        generateBenefitContent(response.data);
      }
    } catch (error) {
      console.error("Error al enviar la encuesta:", error);
    }
  });

  useEffect(() => {
    setModalOpen(!!surveyId);
  }, [surveyId]);

  return (
    <>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        size="xl"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        closeOnClickOutside={false}
        closeOnEscape={false}
        classNames={{
          content: "surveyFormat-modalStyling",
          header: "surveyFormat-modalHeader",
        }}
      >
        <DecorativeEmptyCircle
          size={100}
          opacity={0.2}
          bottom={15}
          left={75}
          color="#8BA7B2"
          borderWidth={16}
        />
        <DecorativeEmptyCircle
          size={100}
          opacity={0.2}
          bottom={110}
          right={75}
          color="#8BA7B2"
          borderWidth={16}
        />
        {showBanner ? (
          <DiscountBanner
            handleDismiss={handleDismiss}
            setModalOpen={setModalOpen}
            discountInfo={surveyJson.benefit}
            setShowBanner={setShowBanner}
          />
        ) : (
          <>
            <h3 className="surveyFormat-surveyHeader">
              ¡Completá esta breve encuestra!
            </h3>
            <Survey model={surveyModel} />
          </>
        )}
      </Modal>
      <Modal
        opened={benefitModal}
        onClose={() => setBenefitModal(false)}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        closeOnClickOutside={false}
        closeOnEscape={false}
        classNames={{
          content: "surveyFormat-modalStyling",
          header: "surveyFormat-modalHeader",
        }}
      >
        <DecorativeEmptyCircle
          size={100}
          opacity={0.2}
          bottom={15}
          left={75}
          color="#8BA7B2"
          borderWidth={16}
          zIndex={-1}
        />
        <DecorativeEmptyCircle
          size={100}
          opacity={0.2}
          bottom={150}
          right={75}
          color="#8BA7B2"
          borderWidth={16}
          zIndex={-1}
        />
        <DecorativeEmptyCircle
          size={100}
          opacity={0.2}
          bottom={160}
          right={475}
          color="#8BA7B2"
          borderWidth={16}
          zIndex={-1}
        />
        <DecorativeEmptyCircle
          size={180}
          opacity={0.2}
          bottom={-50}
          right={170}
          color="#8BA7B2"
          borderWidth={16}
          zIndex={-1}
        />
        {benefitContent}
      </Modal>
    </>
  );
};
export default SurveysFormat;
