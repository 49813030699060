import './PrevExamPage.css'
import {useNavigate, useParams} from 'react-router-dom'
import PrevExamInfoCard from '../../features/PrevExamPage/PrevExamInfoCard/PrevExamInfoCard'
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Skeleton } from '@mantine/core';
import AppContext from '../../contexts/AppContext';
import { capitalize } from '../../utils/capitalize'
import AuthContext from "../../contexts/AuthContext";
import CourseClassHeaderNavigation from '../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation';
import showNotification from '../../utils/showNotification';
import { ERROR_HANDLER } from '../../utils/error-handler';

export default function PrevExamPage() {
   const { authToken } = useContext(AuthContext)
   const { currentUser } = useContext(AppContext)
   const { courseName, examId } = useParams()
   const navigate = useNavigate();
   const [exam, setExam] = useState(null)
   const [state, setState] = useState({
      error: null,
      isLoading: false
   })
   useEffect(() => {
      const getData = async () => {
         setState(prevState => ({ ...prevState, isLoading: true }))
         try {
            const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/exams/beforeStart/${examId}`
            const { data } = await axios.get(url,
               {
                  headers: {
                     "Content-Type": "application/json",
                     "Authorization": "Bearer " + authToken,
                  }
               }
            )
            setExam(data)
         } catch (err) {
            console.error(err)
            setState(prevState => ({
               ...prevState,
               error: 'Lo siento, ha ocurrido un error. No hemos encontrado el exámen'
            }))
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema con la información del exámen.",
               message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            })
         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      }
      getData()
   }, [examId, currentUser._id])

   const lessonTitle = capitalize(courseName.replaceAll('-', ' '))

   return (
      <section className='PrevExamPage-container'>
         <CourseClassHeaderNavigation 
            title={lessonTitle} 
            onNavigate={() => navigate(`/campus/dashboard`)} 
            btnText='Volver al inicio'
            />


         {state.isLoading && <Skeleton style={{ minHeight: '450px' }} />}

         {state.error && <p className='PrevExamPage-error-message'>{state.error}</p>}


         {exam !== null && !state.error && !state.isLoading &&
            <>
               <h3 className='PrevExamPage-subtitle'>Antes de comenzar</h3>

               < PrevExamInfoCard
                  id={examId}
                  headerTitle={exam.name}
                  duration={exam.duration}
                  tryQuantity={exam.tryQuantity}
                  questionCuantity={exam.questionCuantity}
                  approvalPercentage={exam.approvalPercentage}
                  hasStartedExam={exam.hasStartedExam}
                  hasEndedSessionTime={exam.hasEndedSessionTime}
               />

               {/* < PrevExamPageFooter /> */}
            </>
         }
      </section>
   )
}
