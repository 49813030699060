import { useContext, useEffect, useState } from "react";
import axios from "axios";
import SurveysFormat from "../../components/SurveysFormat/SurveysFormat";
import AuthContext from "../../contexts/AuthContext";

const Surveys = ({ remainingSurvey }) => {
  const [surveyJson, setSurveyJson] = useState(null);
  const { authToken } = useContext(AuthContext);
  const surveyId = remainingSurvey[0]; 

  const getSurveys = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}campus/users/survey/${surveyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      setSurveyJson(response.data);
    } catch (error) {
      console.error("Error fetching survies:", error);
    }
  };

  const handleDismiss = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}campus/users/uploadSurvey`,
        { surveyAnswers: [], surveyId: surveyId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
    } catch (error) {
      console.error("Error dismissing survey", error);
    }
  };
  
  useEffect(() => {
    if (surveyId && Object.keys(surveyId).length > 0) {
      getSurveys();
    }
  }, [surveyId]);

  if (!surveyJson) return null;
  return (
    <>
      <SurveysFormat
        surveyId={surveyId}
        handleDismiss={handleDismiss}
        surveyJson={surveyJson}
        setSurveyJson={setSurveyJson}
      />
    </>
  );
};
export default Surveys;
